<template>
  <v-simple-table>
    <thead v-if="header">
      <tr>
        <th colspan="2">
          {{ typeof header === 'function' ? header(state) : header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(field, $fieldIndex) in fields"
        :key="$fieldIndex"
      >
        <td width="50%">{{ typeof field.label === 'function' ? field.label(state) : (field.label || '') }}</td>
        <td>
          <v-select
            v-if="field.options && field.options.length"
            :items="field.options"
            outlined
            hide-details
            :value="getFieldValue(field)"
            @input="handleInput(field, $event)"
          />
          <v-text-field
            v-else
            :class="{'highlight-field': !field.input}"
            :type="field.type || 'number'"
            outlined
            :hide-details="!getFieldErrors(field)"
            :error-messages="getFieldErrors(field)"
            :value="getFieldValue(field)"
            :clearable="!!field.input"
            :readonly="!field.input"
            :prefix="field.prefix"
            :suffix="field.suffix ? (typeof field.suffix === 'function' ? field.suffix(state) : field.suffix) : (field.unit ? unitName(field.unit) : null)"
            @input="handleInput(field, $event)"
          ></v-text-field>
        </td>
      </tr>
    </tbody>
  </v-simple-table>
</template>

<style lang="scss" scoped>
.v-data-table th {
  text-align: center;
}
td {
  padding: 5px !important;
}
</style>

<script>
import flatten from 'lodash/flatten'
import round from 'lodash/round'

export default {
  props: {
    header: {
      type: [String, Function],
      default: ''
    },
    fields: {
      type: Array,
      default: () => []
    },
    state: {
      type: Object,
      default: () => ({})
    },
    schema: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    handleInput (field, value) {
      console.log('handle input', field, value)
      if (!field.key) {
        return
      }
      this.$emit('input', { [field.key]: value })
    },
    getFieldValue (field) {
      let req = field.require
      if (req === '*') {
        req = flatten(
          this.schema.sections.map(section => section.fields || [])
        )
          .filter(field => !!field.input && field.key)
          .map(field => field.key)
      }
      if (req && req.length) {
        for (const key of req) {
          if ([undefined, null, ''].includes(this.state[key])) {
            return ''
          }
        }
      }

      let value = this.state[field.key]
      if (field.round !== 'undefined') {
        if (field.round === 'ceil') {
          value = Math.ceil(value)
        } else if (field.round === 'floor') {
          value = Math.floor(value)
        } else if (field.round === 0) {
          value = Math.round(value)
        } else if (field.round * 1 > 0) {
          value = round(value, field.round)
        }
      }
      return value
    },
    getFieldErrors (field) {
      if (typeof field.error !== 'function') {
        return null
      }
      const error = field.error(this.state)
      return error
    }
  }
}
</script>
