<template>
  <v-app
    style="background: white;"
  >
    <v-app-bar
      height="300"
      src="@/assets/carbon-fiber.jpg"
      :class="{'app-small': appSmall}"
      class="highlight-app-bar"
    >
      <div class="header-container">
        <div class="header-content">
          <div class="logo">
            <a href="http://highlightindustries.com/">
              <img src="@/assets/logo.gif" />
            </a>
          </div>
          <div class="catch-phrase">
            HIGHEST QUALITY, HIGHEST VALUE, HIGHEST TECHNOLOGY IN PACKAGING
          </div>
        </div>
        <div
          v-if="!$root.standalone"
        >
          <v-tabs
            centered
            show-arrows
            hide-slider
            background-color="transparent"
          >
            <v-tab href="http://highlightindustries.com/">Home</v-tab>
            <v-tab href="http://highlightindustries.com/products">Products</v-tab>
            <v-tab href="http://highlightindustries.com/about">About</v-tab>
            <v-tab href="http://highlightindustries.com/contact">Contact</v-tab>
            <v-tab :to="{ name: 'tools' }">Tools</v-tab>
            <v-tab href="http://highlightindustries.com/parts-service">Parts &amp; Service</v-tab>
          </v-tabs>
        </div>
      </div>
    </v-app-bar>
    <div class="stretch-header">
      <v-img
        src="@/assets/stretch-header.jpg"
        :aspect-ratio="appSmall ? 4.5 / 1 : undefined"
      />
    </div>
    <div style="padding-bottom: 200px;">
      <router-view></router-view>
    </div>
  </v-app>
</template>

<style lang="scss">
* {
  font-family: "open sans", "Arial";
}
.highlight-app-bar {
  .v-toolbar__content {
    padding-bottom: 0;
  }
}
.highlight-field {
  fieldset, input {
    background-color: #ffffb3;
  }
}
</style>

<style lang="scss" scoped>
.header-container {
  margin: 0 auto;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header-content {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-grow: 100;
  flex-direction: column;
  justify-content: center;
  .logo {
    margin: 0 auto;
    max-width: 550px;
    img {
      max-width: 100%;
    }
  }
  .catch-phrase {
    padding-top: 20px;
    margin: 0 auto;
    text-align: center;
    font-style: italic;
    color: #fff;
    font-size: 22px;
  }
}
.app-small {
  .catch-phrase {
    font-size: 16px;
  }
}
.theme--light.v-tabs {
  .v-tab {
    background-color: rgba(153, 153, 153, 1);
    color: #fff !important;
    text-transform: uppercase;
    &:first-child {
      border-top-left-radius: 15px;
    }
    &:last-child {
      border-top-right-radius: 15px;
    }
    &:hover {
      background-color: #606060;
    }

    &.v-tab--active {
      color: #000 !important;
      background-color: #fff;
    }
  }
}
</style>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>
