import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    units: 'Imperial'
  },
  mutations: {
    setUnits: (state, units) => {
      state.units = units
    }
  },
  actions: {

  }
})
