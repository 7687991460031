<template>
  <v-container
    class="text-center"
  >
    &nbsp;
  </v-container>
</template>

<script>

export default {
  components: {
  },
  created () {
    this.$router.push({ name: 'tools' })
  }
}
</script>
