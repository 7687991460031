// Unit keys are in imperial, values are metric
// Factor is to convert metric to imperial
const unitMap = {
  inches: {
    name: 'millimeters',
    factor: 0.0393701
  },
  feet: {
    name: 'meters',
    factor: 3.28084
  },
  pounds: {
    name: 'kgs',
    factor: 2.20462
  },
  pound: {
    name: 'kg',
    factor: 2.20462
  },
  ounces: {
    name: 'grams',
    factor: 0.035274
  },
  miles: {
    name: 'kilometers',
    factor: 0.621371
  },
  gauge: {
    name: 'micron',
    factor: 4
  }
}

module.exports = unitMap
