import Vue from 'vue'
import Router from 'vue-router'
import Home from './modules/Home.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/tools',
      name: 'tools',
      component: () => import(/* webpackChunkName: "tools" */ './modules/Tools/Tools.vue'),
      children: [
        {
          name: 'tools-film-thickness',
          path: 'film-thickness',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/FilmThickness')
        },
        {
          name: 'tools-stretch-level',
          path: 'stretch-level',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/StretchLevel')
        },
        {
          name: 'tools-film-comparison',
          path: 'film-comparison',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/FilmComparison')
        },
        {
          name: 'tools-cost-of-film',
          path: 'cost-of-film',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/CostOfFilm')
        },
        {
          name: 'tools-containment-force',
          path: 'containment-force',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/ContainmentForce')
        },
        {
          name: 'tools-load-diagonal',
          path: 'load-diagonal',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/LoadDiagonal')
        },
        {
          name: 'tools-film-roll-related',
          path: 'film-roll-related',
          component: () => import(/* webpackChunkName: "film-thickness" */ './modules/Tools/FilmRollRelated')
        }
      ]
    },
    { path: '*', redirect: '/' }
  ]
})
