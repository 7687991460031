import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import round from 'lodash/round'
import { mapMutations, mapState } from 'vuex'
import unitMap from '@/lib/unitMap'
import InputSection from '@/components/InputSection'
import '@/registerServiceWorker'

Vue.config.productionTip = false

Vue.mixin({
  components: {
    InputSection
  },
  data () {
    return {
      standalone: (window.matchMedia('(display-mode: standalone)').matches) || (window.navigator.standalone) || document.referrer.includes('android-app://')
    }
  },
  computed: {
    appSmall () {
      return this.$vuetify.breakpoint.smAndDown
    },
    ...mapState(['units'])
  },
  methods: {
    round,
    setState (obj) {
      console.log('set state', obj)
      Object.entries(obj).forEach(([key, value]) => this.$set(this, key, value))
    },
    unitName (key) {
      if (this.units === 'Imperial') {
        return key
      }
      if (!unitMap[key]) {
        return key + '?'
      }
      return unitMap[key].name
    },
    ...mapMutations(['setUnits'])
  }
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
